var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',[_c('v-flex',[_c('page-tab',{attrs:{"title":"Delivery address health check"}}),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-checkbox',{attrs:{"label":_vm.$t('c:customers-supplier-consignee-list:Show customers without access'),"dense":""},model:{value:(_vm.showCustomersWithoutAccess),callback:function ($$v) {_vm.showCustomersWithoutAccess=$$v},expression:"showCustomersWithoutAccess"}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-checkbox',{attrs:{"label":_vm.$t('c:customers-supplier-consignee-list:Show only customers with problems'),"dense":""},model:{value:(_vm.showOnlyCustomersWithProblems),callback:function ($$v) {_vm.showOnlyCustomersWithProblems=$$v},expression:"showOnlyCustomersWithProblems"}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-checkbox',{attrs:{"label":_vm.$t(
                'c:customers-supplier-consignee-list:Show only customers that made at least an order in last 6 months'
              ),"dense":""},model:{value:(_vm.showOnlyCustomersWithRecentOrders),callback:function ($$v) {_vm.showOnlyCustomersWithRecentOrders=$$v},expression:"showOnlyCustomersWithRecentOrders"}})],1)],1),(_vm.filterSupplierId === null)?_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-checkbox',{attrs:{"label":_vm.$t('c:customers-supplier-consignee-list:Show suppliers not active at any webshop'),"dense":""},model:{value:(_vm.showSuppliersNotActiveAtWebshop),callback:function ($$v) {_vm.showSuppliersNotActiveAtWebshop=$$v},expression:"showSuppliersNotActiveAtWebshop"}})],1)],1):_vm._e(),_c('v-autocomplete',{attrs:{"items":_vm.suppliers,"label":"Supplier","item-text":"name","item-value":"id","dense":"","clearable":""},on:{"change":_vm.prepHeaders},model:{value:(_vm.filterSupplierId),callback:function ($$v) {_vm.filterSupplierId=$$v},expression:"filterSupplierId"}})],1),_c('list-view',{attrs:{"title":"","url":_vm.url,"query":_vm.query,"headers":_vm.headers,"top-actions":_vm.topActions},scopedSlots:_vm._u([{key:"item.customerNumber",fn:function(ref){
              var item = ref.item;
return [_c('a',{attrs:{"href":_vm.appendSitePrefix('/' + item.id + '/customer/show/null')}},[_vm._v(_vm._s(item.customerNumber))])]}},_vm._l((_vm.suppliers),function(supplier){return {key:("item.supplier" + (supplier.id)),fn:function(ref){
              var item = ref.item;
return [_c('span',{key:supplier.id,domProps:{"innerHTML":_vm._s(_vm.getSupplierStatus(item.supplierConsignees, supplier.id, item.id))}})]}}}),{key:"item.consigneePartyId",fn:function(ref){
              var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.getConsigneeFieldCurrentSupplier('consigneePartyId', item.supplierConsignees))}})]}},{key:"item.buyerPartyId",fn:function(ref){
              var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.getConsigneeFieldCurrentSupplier('buyerPartyId', item.supplierConsignees))}})]}},{key:"item.username",fn:function(ref){
              var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.getConsigneeFieldCurrentSupplier('username', item.supplierConsignees))}})]}},{key:"item.password",fn:function(ref){
              var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.getConsigneeFieldCurrentSupplier('password', item.supplierConsignees))}})]}},{key:"item.status",fn:function(ref){
              var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.getConsigneeFieldCurrentSupplier('status', item.supplierConsignees))}}),(_vm.getConsigneeFieldCurrentSupplier('statusDetails', item.supplierConsignees) !== '')?_c('v-icon',{staticClass:"ml-1",attrs:{"small":""},on:{"click":function($event){_vm.openStatusDialog(_vm.getConsigneeFieldCurrentSupplier('statusDetails', item.supplierConsignees))}}},[_vm._v(" fa-info ")]):_vm._e()]}}],null,true)}),_c('v-dialog',{attrs:{"width":"auto"},model:{value:(_vm.statusDetailsDialog),callback:function ($$v) {_vm.statusDetailsDialog=$$v},expression:"statusDetailsDialog"}},[_c('v-card',[_c('v-card-text',[_c('span',{staticClass:"status-details-content",domProps:{"innerHTML":_vm._s(_vm.statusDetailsContent)}})]),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary","block":""},on:{"click":function($event){_vm.statusDetailsDialog = false}}},[_vm._v("Close Dialog")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }