





































































































import Vue from 'vue'
import Component from 'vue-class-component'
import ListView from '@/components/list/ListView.vue'
import { appendSitePrefix } from '@/utils/routeUtils'
import { Watch } from 'vue-property-decorator'
import PageTab from '@/components/ui/PageTab.vue'
import b64toBlob from 'b64-to-blob'

@Component({
  methods: { appendSitePrefix },
  components: {
    ListView,
    PageTab,
  },
})
export default class List extends Vue {
  private headers = []
  private overviewHeaders = []
  private supplierHeaders = []

  private showCustomersWithoutAccess = false
  private showSuppliersNotActiveAtWebshop = false
  private showOnlyCustomersWithProblems = false
  private showOnlyCustomersWithRecentOrders = false
  private filterSupplierId = null

  private topActions = []

  private sortOrder = 'asc'
  private sortColumn = 'customerNumber'

  private suppliers = []

  private statusDetailsDialog = false
  private statusDetailsContent = ''

  private created() {
    this.showCustomersWithoutAccess = this.getStoredParamBool('showCustomersWithoutAccess')
    this.$axios.get('/v4/site/suppliers/connected').then((response) => {
      this.suppliers = response.data.data
      this.prepHeaders()
    })
  }

  private mounted() {
    this.renderTopActions()
  }

  private renderTopActions() {
    this.topActions = []

    if (this.filterSupplierId !== null) {
      this.topActions.push({
        id: 'exportToExcel',
        label: 'Export to Excel',
        icon: 'fa-download',
        click: () => {
          this.$axios.get(this.url + '?exportToExcel=1').then((response) => {
            const blob = b64toBlob(response.data.excelData, 'application/vnd.ms-excel')
            if (!window.navigator.msSaveOrOpenBlob) {
              // BLOB NAVIGATOR
              const url = window.URL.createObjectURL(blob)
              const link = document.createElement('a')
              link.href = url
              link.setAttribute('download', 'test')
              document.body.appendChild(link)
              link.click()
            } else {
              // BLOB FOR EXPLORER 11
              const url = window.navigator.msSaveOrOpenBlob(blob, 'test')
            }
          })
        },
      })
    }
  }

  private prepHeaders() {
    this.overviewHeaders = [
      {
        text: 'Customer no',
        value: 'customerNumber',
      },
      {
        text: 'Name',
        value: 'name',
      },
      {
        text: 'Organization number',
        value: 'regNo',
      },
    ]

    this.suppliers.forEach((value) => {
      this.overviewHeaders.push({
        text: value.name,
        value: 'supplier' + value.id,
        filter: { disable: true },
      })
    })

    this.supplierHeaders = [
      {
        text: 'Customer no',
        value: 'customerNumber',
      },
      {
        text: 'Name',
        value: 'name',
      },
      {
        text: 'Organization number',
        value: 'regNo',
      },
      {
        text: 'Address 1',
        value: 'address1',
      },
      {
        text: 'Address 2',
        value: 'address2',
      },
      {
        text: 'Zipcode',
        value: 'zipcode',
      },
      {
        text: 'City',
        value: 'city',
      },
      {
        text: 'ConsigneePartyID',
        value: 'consigneePartyId',
      },
      {
        text: 'BuyerPartyID',
        value: 'buyerPartyId',
      },
      {
        text: 'Username',
        value: 'username',
      },
      {
        text: 'Password',
        value: 'password',
      },
      {
        text: 'Status',
        value: 'status',
      },
    ]

    if (this.filterSupplierId === null) {
      this.headers = this.overviewHeaders
    } else {
      this.headers = this.supplierHeaders
    }
  }

  getQueryParam(key): string {
    if (this.$route.query) {
      const value = this.$route.query[key]
      if (value instanceof Array) {
        return value[0] ? '' + value[0] : ''
      } else {
        return value ? '' + value : ''
      }
    } else {
      return ''
    }
  }

  private openStatusDialog(content) {
    this.statusDetailsDialog = true
    this.statusDetailsContent = content
  }

  private getSupplierStatus(supplierConsignees, supplierId, customerId) {
    let result = null

    for (let i = 0; i < supplierConsignees.length; i++) {
      if (supplierConsignees[i].supplierId === supplierId) {
        result = supplierConsignees[i].status
        break
      }
    }

    return this.formatStatus(result, supplierId, customerId)
  }

  private formatStatus(status, supplierId, customerId) {
    if (status === null) {
      return ''
    } else {
      let color = 'red'
      switch (status) {
        case 'not-checked':
          color = 'blue'
          break
        case 'OK':
          color = 'green'
          break
        case 'no-mode':
        case 'no-check':
        case 'unknown-mode':
          color = 'purple'
          break
      }

      return (
        '<a href="' +
        appendSitePrefix('/U/deliv_addr_check/details?customer_id=' + customerId + '&supplier_id=' + supplierId) +
        '" class="' +
        color +
        '--text" target="_blank">' +
        status +
        '</a>'
      )
    }
  }

  private getConsigneeFieldCurrentSupplier(field, supplierConsignees) {
    let supplierConsignee = null

    for (let i = 0; i < supplierConsignees.length; i++) {
      if (supplierConsignees[i].supplierId === this.filterSupplierId) {
        supplierConsignee = supplierConsignees[i]
        break
      }
    }

    if (supplierConsignee === null) {
      return ''
    }

    if (field === 'status') {
      return this.formatStatus(supplierConsignee[field], supplierConsignee.supplierId, supplierConsignee.customerId)
    }

    return supplierConsignee[field]
  }

  private get query() {
    return {
      supplierId: this.filterSupplierId,
      showCustomersWithoutAccess: this.showCustomersWithoutAccess,
      showOnlyCustomersWithProblems: this.showOnlyCustomersWithProblems,
      showOnlyCustomersWithRecentOrders: this.showOnlyCustomersWithRecentOrders,
    }
  }

  private getStoredParamBool(key: string): boolean {
    if (this.$route.query[key] !== undefined) {
      return !!this.$route.query[key]
    } else {
      return this.getLocalStorageBool(key)
    }
  }

  private getLocalStorageBool(key: string): boolean {
    return !!localStorage.getItem('customer-supplier-consignee-list-' + key)
  }

  private setLocalStorageBool(key: string, value: boolean) {
    localStorage.setItem('customer-supplier-consignee-list-' + key, value ? '1' : '')
  }

  @Watch('showCustomersWithoutAccess')
  private onChangeShowCustomersWithoutAccess() {
    this.setLocalStorageBool('showCustomersWithoutAccess', this.showCustomersWithoutAccess)
  }

  @Watch('filterSupplierId')
  private onChangeFilterSupplierId() {
    this.renderTopActions()
  }

  private get url() {
    return '/v4/site/supplier/consignee'
  }

  @Watch('$route', { deep: true })
  private routeToParams() {
    const sortBy = this.getQueryParam('sortBy')
    // NOTE: Temp field mapping for `Export to CSV` until we move that to v4
    switch (sortBy) {
      case 'createdAt':
        this.sortColumn = '_created'
        break
      case 'customerNumber':
      case 'invoiceEmail':
      case 'defaultPayerName':
      case 'priceListName':
      case 'regNo':
      case 'groupId':
      case 'subGroupId':
        this.sortColumn = ((str: string): string =>
          str
            .split('')
            .map((letter, idx) => {
              return letter.toUpperCase() === letter ? `${idx !== 0 ? '_' : ''}${letter.toLowerCase()}` : letter
            })
            .join(''))(sortBy)
        break
      default:
        this.sortColumn = sortBy ? sortBy.toLocaleUpperCase() : 'name'
        break
    }

    this.sortOrder = this.getQueryParam('sortDesc') === '1' ? 'desc' : 'asc'
  }
}
